<template>
    <div class="header">
        <div class="header-l">
            <div v-if="showLicon">
                <img class="fenlei" v-if="!showList" src="@/assets/slices/ic_fenlei.png" alt="" @click="showNav(true)">
                <img class="fenlei" v-else src="@/assets/slices/ic_fenlei1.png" alt=""
                @click="showNav(false)">
            </div>
            <img class="logo" src="@/assets/class/logo.png" alt="" @click="toIndex">
        </div>
        <div class="header-r">
            <div class="header-r-body">
                <div>
                    <img src="@/assets/slices/ic_sousuo.png" alt="">
                    <input v-model="searchText" placeholder="keywords" @keyup.enter="getSearch" type="text">
                </div>
                <button @click="getSearch">search</button>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile } from '@/utils/api'
import eventBus from '@/utils/eventBus';
    export default {
        name:'headerNov',
        data() {
            return {
                searchText:'',
                showLicon:false,
                showList:true
            }
        },
        mounted() {
            if(isMobile()){
                this.showList = false;
                this.showLicon = true;
            }
            eventBus.$on('clickMask',key=>{                
                this.showList = key
            })
        },
        methods: {
            getSearch(){
                if(this.$route.path!='/'){
                    this.$router.push({
                        name: 'index',
                        params: {
                            searchText:this.searchText
                        }
                    })
                }else{
                    eventBus.$emit('searchKey',this.searchText)
                }
            },
            toIndex(){
                if(this.$route.path!='/'){
                    this.$router.push('/')
                }else{
                    console.log(11);
                    
                    this.$router.go(0)
                }
            },
            showNav(item){
                this.showList = item;
                eventBus.$emit('showlist',item)
            }
        },
    }
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
    .header{
        padding: 0 10px;
        width: 100vw;
        top: 0;
        .header-r{
            display: none;
        }
    }
}
@media (min-width: 768px) {
    .header{
        padding: 0 46px;
        box-sizing: border-box;
    }
}
.header{
    width: 100vw;
    position: fixed;
    top: 0;
    height: 60px;
    background-color: #1F2030;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    border-bottom: 1px solid #373737;
    .header-l{
        display: flex;
        align-items: center;
        .fenlei{
            width: 41px;
            cursor: pointer;
            margin-right: 15px;
        }
        .logo{
            height: 30px;
            cursor: pointer;
        }
    }
    .header-r{
        .header-r-body{
            width: 560px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            div{
                width: 470px;
                height: 40px;
                border-radius: 20px;
                display: flex;
                align-items: center;
                background-color: #373952;
                height: 100%;
                img{
                    width: 30px;
                    height: 30px;
                }
                input{
                    width: 400px;
                    height: 80%;
                    display: block;
                    border: none;
                    outline: none;
                    background-color: #373952;
                    color: #888;
                }
            }
            button{
                width: 83px;
                height: 40px;
                background: #6842FF;
                border-radius: 10px;
                border: none;
                cursor: pointer;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 18px;
                color: #FFF0F0;
                font-style: normal;
            }
        }
    }
}
</style>