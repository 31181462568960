<template>
    <div>
        <div class="body-l" v-if="showList">
            <div class="mask" @click="clickMask"></div>
            <!-- :style="{background:selectName!=item.name?'#202020':'#fff'}" -->
            <div
            :class="{
                'body-l-item':true,
                'select-back':selectName==item.name
            }"
            v-for="item in classList"
            :key="item.name"
            @click="getClass(item)"
            >
                <img :src="item.url" alt="">
                
                <span :style="{color:selectName!=item.name?'#c2c2c2':'#A48EFF'}">{{item.name}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import eventBus from '@/utils/eventBus.js'
// import navMenuList from "@/utils/navMenu"
import { isMobile } from "@/utils/api"
import { getClassList } from '@/api/games'
    export default {
        data() {
            return {
                showList: true,
                selectName:'All',
                isMobile,
                classList:[
                    {
                        name: 'All',
                        url: require('@/assets/nclass/All.png'),
                        surl: require('@/assets/class/ic_all1.png')
                    },
                ]
            }
        },
        mounted() {
            eventBus.$on('showlist',item=>{                
                this.showList = item;
            })
            this.getClassList();
        },
        methods: {
            clickMask(){
                eventBus.$emit('clickMask',false);
                this.showList = false;
            },
            getClass(key){
                if(!isMobile()){
                    window.scrollTo({
                        top: 364,
                        behavior: 'smooth'
                    });
                }
                this.selectName = key.name;
                if(this.$route.path!='/'){
                    this.$router.push({
                        name:'index',
                        params:{
                            key
                        }
                    })
                }else{
                    eventBus.$emit('gameList',key);
                }
            },
            async getClassList(){
                let classlist = await getClassList();
                classlist.forEach(item => {
                    let name = item.key_name;
                    this.classList.push({
                        url:require(`@/assets/nclass/${name}.png`),
                        name
                    })
                })                
            }
        },
    }
</script>

<style lang="less" scoped>
@media (max-width: 768px) {
    .body-l{
        width: 186px;
        position: fixed;
        top: 60px;
        left: 0;
        background-color: #0C0D14;
        height: calc(100vh - 60px);
        position: fixed;
        top: 60px;
        left: 0;
        overflow: auto;
        .mask{
            position: fixed;
            top: 60px;
            left: 186px;
            width: calc(100vw - 186px);
            height: calc(100vh - 60px);
            background-color: rgba(51, 51, 51, 0.9);
        }
        .body-l-item{
            width: 130px;
            height: 45px;
            display: flex;
            align-items: center;
            padding-left: 5px;
            border-radius: 10px;
            box-sizing: border-box;
            margin: 10px auto 0;
            cursor: pointer;
            &:hover span{
                transform: translateX(5px);
                transition: transform 0.2s linear;
            }
            span{
                margin-left: 12px;
                width: 24px;
                height: 28px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                text-align: left;
                font-style: normal;
            }
            img{
                width: 27px;
                height: 27px;
            }
        }
    }
}
@media (min-width: 768px) {
    .body-l{
        &::-webkit-scrollbar {
            width: 0;
        }
        width: 150px;
        background-color: #0C0D14;
        height: calc(100vh - 187px);
        position: fixed;
        top: 60px;
        overflow: auto;
        .select-back{
            background-image: linear-gradient(to right, #A48EFF 50%, #A48EFF 50%);
            background-size: 6px 45px;
            background-repeat: no-repeat;
        }
        .body-l-item{
            // width: 130px;
            // height: 33px;
            // display: flex;
            // // justify-content: space-between;
            // padding-left: 5px;
            // box-sizing: border-box;
            // margin: 11px auto 0;
            // cursor: pointer;
            padding-left: 10px;
            width: 130px;
            height: 45px;
            display: flex;
            align-items: center;
            padding-left: 5px !important;
            box-sizing: border-box;
            margin: 10px 0;
            cursor: pointer;
            &:hover span{
                transform: translateX(5px);
                transition: transform 0.2s linear;
            }
            &:hover span{
                transform: translateX(5px);
                transition: transform 0.2s linear;
            }
            span{
                margin-left: 12px;
                width: 24px;
                height: 28px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                line-height: 28px;
                text-align: left;
                font-style: normal;
            }
            img{
                width: 34px;
                height: 34px;
            }
        }
    }
}
</style>